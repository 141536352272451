<template>
    <section id="published">
      <v-card class="custom-border border pa-2">
        <section class="d-flex flex-row align-center">
          <v-card-title>
            <h4 class="poppins secondary-1--text fw600">
                {{ type }} Courses
            </h4>
          </v-card-title>
          <v-spacer />
          <div class="d-flex justify-end">
            <v-btn 
              text 
              small
              @click="gallery =  true"
              class="hidden-sm-and-down"
            >
              <v-icon color="" :class="gallery ? 'primary--text' : 'secondary-2--text'">
                mdi-view-gallery-outline
              </v-icon>
            </v-btn>
            <v-btn 
              text 
              small
              @click="gallery = false"
              class="hidden-sm-and-down"
            >
              <v-icon color="" :class="!gallery ? 'primary--text' : 'secondary-2--text'">
                mdi-format-list-bulleted
              </v-icon>
            </v-btn>
          </div>
        </section>
  
        <section class="mx-3">
          <SearchBar 
            :placeholder="'Search course'" 
            :outlined="true" 
            :value.sync="search" 
            @clear="clearSearch"
            @search="onSearch"
            class="mb-5"/>
        </section>
  
        <LinearProgress  v-if="loading" class="my-3"/>
        
        <v-card-text v-if="courses.length>0" class="d-flex" :class="[$vuetify.breakpoint.mobile && 'justify-center', gallery ? 'flex-wrap' : 'flex-column']">
          <CourseCardGallery 
            v-if="gallery"
            v-for="(item,i) in courses"
            :key="item.id"
            :i="i" 
            :item="item" 
            :gallery="gallery"
            class="ma-3"
            @fetchCourses="getData"
          />
          <CourseCardList 
            v-if="!gallery"
            v-for="(item,i) in courses"
            :key="item.id"
            :i="i" 
            :item="item" 
            class="ma-3"
            @fetchCourses="getData"
          />
        </v-card-text>
        <v-sheet
          color="transparent"
          class="d-flex flex-column align-center justify-center pb-10"
          height="500"
          v-if="!loading && courses.length === 0 && type === 'Published'"
        >
          <div class="">
            <v-img 
              max-width="250" 
              :src="require('@/assets/default/empty_published.png')" />
          </div>
          <h3 class="text-center poppins secondary-1--text fw600 my-5 text-center">
            You currently have no published courses.
          </h3>
          <div
            class="text-decoration-underline f14 poppins primary--text"
            @click="$emit('createCourse')"
          >
            Create Course
          </div>
        </v-sheet>
        <v-sheet
          color="transparent"
          class="d-flex flex-column align-center justify-center pb-10"
          height="500"
          v-if="!loading && courses.length === 0 && type === 'Unpublished'"
        >
          <div class="">
            <v-img 
              max-width="250" 
              :src="require('@/assets/default/empty_dashboard.png')" />
          </div>
          <h3 class="text-center poppins secondary-1--text fw600 my-5 text-center">
            You currently have no unpublished courses.
          </h3>
          <div
            class="text-decoration-underline f14 poppins primary--text"
            @click="$emit('createCourse')"
          >
            Create Course
          </div>
        </v-sheet>
        <v-sheet
            color="transparent"
            class="d-flex flex-column align-center justify-center pb-10"
            height="500"
            v-if="courses.length === 0 && !loading && type === 'Drafted'"
        >
            <div class="">
            <v-img 
                max-width="300" 
                :src="require('../../assets/default/empty_drafts.png')"/>
            </div>
            <h3 class="poppins secondary-1--text fw600 my-5">Nothing in drafts</h3>
        </v-sheet>
        <FormPagination 
          :pageCount="pageCount" 
          :page="page"
          :paginate="paginationPaginate"
          @page="onPageChange" 
          @paginate="onPaginateChange"/>
      </v-card>
    </section>
  </template>
  
  <script>
  import { mapActions, mapState } from "vuex";
  import searchAndPaginateMixin from '@/mixins/searchAndPaginateMixin';
  import CourseCardGallery from "../../components/teacher/course/CourseCardGallery.vue";
  import CourseCardList from "../../components/teacher/course/CourseCardList.vue";
  
  export default {
    mixins: [searchAndPaginateMixin],
    components: {
      CourseCardGallery,
      CourseCardList
    },
    data: () => ({
      loading: false,
      gallery: true,
      filter: null,
      pageCount: 1,
      type: ''
    }),
    computed: {
      ...mapState("instructor", {
        courses: (state) => state.courses,
      }),

      paginationPaginate(){
        return String(this.paginate)
      }
    },
    mounted() {
        window.addEventListener('resize', this.onResize, {passive: true})
        this.onResize()
        this.getData()
        this.type = this.$route.name.split(' ')[1]
    },
    methods: {
      ...mapActions("instructor", ["getPublishedCoursesAction", "getUnpublishedCoursesAction", "getDraftsCoursesAction"]),
  
      onResize(){
        if(this.$vuetify.breakpoint.smAndDown || this.$vuetify.breakpoint.mobile){
          this.gallery = false
        }
      },
  
      getData(){
        this.type = this.$route.name.split(' ')[1]

        if(!this.loading) {
          if(this.type === 'Published') {
              this.loading = true
              this.getPublishedCoursesAction({ paginate: Number(this.paginate), page: this.page, search: this.search}).then(res => {
                  this.page = res.current_page
                  this.pageCount = res.last_page
                  this.paginate = String(res.per_page)
                  this.loading = false;
              }).catch(() => {
                  this.loading = false;
              });
          }
          if(this.type === 'Unpublished') {
              this.loading = true
              this.getUnpublishedCoursesAction({ paginate: Number(this.paginate), page: this.page, search: this.search}).then(res => {
                  this.page = res.current_page
                  this.pageCount = res.last_page
                  this.paginate = String(res.per_page)
                  this.loading = false;
              }).catch(() => {
                  this.loading = false;
              });
          }
          if(this.type === 'Drafted') {
              this.loading = true
              this.getDraftsCoursesAction({ paginate: Number(this.paginate), page: this.page, search: this.search}).then(res => {
                  this.page = res.current_page
                  this.pageCount = res.last_page
                  this.paginate = String(res.per_page)
                  this.loading = false;
              }).catch(() => {
                  this.loading = false;
              });
          }
        }
      }
    },
  };
  </script>  